import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import Typography from './Typography'

const Subtitle = props => {
  const { theme, className, children, color, variant, component, type, lang, ...other } = props
  return (
    <Root
      className={className}
      theme={theme}
      color={color}
      type={type}
      {...other}
    >
      <Typography variant={variant} component={component} lang={lang} color={color} type={type}>
        <Content>
          <Icon width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 8L0 0V16L8 8Z"/>
          </Icon>
          <Text>{ children }</Text>
        </Content>
      </Typography>
    </Root>
  )
}

const Root = styled.div`
`

const Content = styled.span`
  display: inline-flex;
  align-items: center;
`

const Text = styled.span`
  margin-left: 12px;
`

const Icon = styled.svg`
  & > path {
    fill: currentColor;
  }
`

export default ThemeConsumer(Subtitle)
